
import { useRepositories } from '@/repository/repositories';
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus';

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo')

type DataType = {
  yearMonth?: Date;
  loading: boolean;
}

export default defineComponent({
  name: "admin-csv",

  setup() {
    const repos = useRepositories();
    return {
      repos,
    }
  },

  data(): DataType {
    return {
      yearMonth: undefined,
      loading: false
    }
  },

  methods: {
    async downloadCsv(): Promise<void> {
      if (this.yearMonth == undefined) {
        ElMessage({
          message: '対象月を選択してください',
          type: "error",
        });
        return;
      }

      this.loading = true;
      try {
        const formatted = dayjs(this.yearMonth as Date).format("YYYY-MM")
        const url = await this.repos.insuranceContractor.getCsvUrl(formatted);
        const a = document.createElement("a")
        a.href = url;
        a.click();
        a.remove();

        this.loading = false;
      } catch (e) {
        this.loading = false;
        ElMessage({
          message: 'CSVダウンロードに失敗しました。',
          type: "error",
        });
        throw new Error(`failed download csv. err: ${e}}`);
      }
    }
  },
});
